var COL = {

    MobileImages: (function () {

        var _init = function () {
            $('[data-bg-src]').each(function (index, item) {
                _loadMedia(item);
            });
        };

        var _loadMedia = function (element) {
            var bgData = JSON.parse($(element).attr('data-bg-src'));
            bgData.sort(function (a, b) { return a.bp - b.bp });

            var elementId = ($(element).attr('id') ? $(element).attr('id') : 'lzyBg-' + parseInt(Math.random() * (1000000 - 900000) + 900000, 10));

            // Smallest breakpoint URL
            $(element).attr('style', 'background-image: url("' + bgData[0].url + '")');   
            var $style = $('<style></style>');
            // Skip first item
            for (var i = 1; i < bgData.length; i++) {
                $style.append('@media screen and (min-width:' + bgData[i].bp + 'px){#' + elementId + ' {background-image:url(' + bgData[i].url + ')!important}}');
            }
            $(element).attr('id', elementId);
            $('body').append($style);
        };

        $(window).on('init.COL', _init);
    })(),
    Header: (function () {
        var _init = function () {
            $(window).on('scroll', _checkScroll);
            _checkScroll();
        },

            _checkScroll = function (evt) {
                var ww = $(window).width();
                var wt = $(window).scrollTop();
                var pb = $('.topBlock header').outerHeight();
                if ($('.topBlock .cookieBanner').is(":visible") && (ww > 992)) {
                    pb += $('.topBlock .cookieBanner').outerHeight();
                }
                if (wt > pb) {
                    $('.mainBlock').addClass('fixed').css("padding-top", pb);
                    $('.topBlock').addClass('fixed');
                    if (wt > this.lastScrollTop) {
                        $('.topBlock').removeClass('in');
                    } else {
                        $('.topBlock').addClass('in');
                    }
                } else {
                    $('.mainBlock').removeClass('fixed').css("padding-top", 0);
                    $('.topBlock').removeClass('fixed');
                }

                this.lastScrollTop = wt;
            };

        $(window).on('init.COL', _init);
    })(),

    ShareIcons: (function () {
        var sel = {
            component: '.article-share__icons .article-share__icon'
        },

            _init = function () {
                var items = $(sel.component);
                if (items.length) {
                    items.each(function () {
                        $(this).on("click", _tryShare);
                    })
                }
            },

            _tryShare = function (evt) {
                var item = $(evt.currentTarget);
                var title = encodeURIComponent($("head title").text().trim());
                var url = encodeURIComponent(window.location.href.trim());

                var options = 'resizable,scrollbars,status,height=420,width=500';
                if (item.hasClass("li")) {
                    window.open('https://www.linkedin.com/shareArticle?mini=true&summary=&source=&url=' + url + '&title=' + title, '_blank', options);

                } else if (item.hasClass("tw")) {
                    window.open('https://twitter.com/intent/tweet?url=' + url + '&text=' + title, '_blank', options);

                } else if (item.hasClass("fb")) {
                    window.open('https://www.facebook.com/sharer/sharer.php?u=' + url, '_blank', options);

                } else if (item.hasClass("wc")) {
                    window.open('http://api.addthis.com/oexchange/0.8/forward/wechat/offer?url=' + url, '_blank', options);

                } else if (item.hasClass("wa")) {
                    window.open('https://web.whatsapp.com/send?text=' + url, '_blank');

                } else if (item.hasClass("em")) {
                    var w = window.open('mailto:?subject=' + title + '&body=' + url, '_blank');
                    w.opener = null;

                    //close the window straight-away, since we don't need it after the users email app launches...
                    var t = setInterval(function () {
                        if (w && w.open && !w.closed) {
                            w.close();
                            clearInterval(t);
                        }
                    }, 500);
                }

                return false;
            };

        $(window).on('init.COL', _init);
    })(),

    Standard: (function () {
        var _init = function () {
            if ($(".equal-height").length > 0) {
                $(".equal-height").equalHeights();
            }

            if ($(".article-list__listitems").length > 0) {
                $(".article-list__listitems").masonry({
                    itemSelector: '.article-list__item',
                    columnWidth: '.article-list__gridsizer',
                    percentPosition: true
                });
            }

            if ($(".article-back a").length > 0) {
                $(".article-back a").on("click", function (evt) {
                    evt.preventDefault();
                    window.history.back();
                    return false;
                });
            }

            $(window).on("resize", _resize);
            _resize();
        },

            _resize = function (evt) {
                $(".stats-panel").each(function () {
                    var max = 0;

                    $(this).find(".feature-panel__details").removeAttr("style");
                    $(this).find(".feature-panel__details").each(function () {
                        var hgt = $(this).outerHeight();
                        if (hgt > max) {
                            max = hgt;
                        }
                    });

                    if (max > 0) {
                        $(this).find(".feature-panel__details").css("height", max);
                    }
                });
                $(".feature-panel").each(function () {
                    var max = 0;

                    $(this).find(".feature-panel__item--white").removeAttr("style");
                    $(this).find(".feature-panel__item--white").each(function () {
                        var hgt = $(this).outerHeight();
                        if (hgt > max) {
                            max = hgt;
                        }
                    });

                    if (max > 0) {
                        $(this).find(".feature-panel__item--white").css("height", max);
                    }
                });
            };

        $(window).on('init.COL', _init);
    })(),

    ClickableTile: (function () {
        var _init = function () {
            $(".clickable-tile").each(function (evt) {
                if ($(this).hasClass("blog")) {
                    return;
                }

                var url = $(this).data("url");
                if ($.trim(url).length == 0) {
                    $(this).removeClass("clickable-tile");
                    $(this).find(".click-mask").remove();
                }
            });

            $(".clickable-tile").on("click", function (evt) {
                if ($(this).hasClass("feature-panel__item")) {
                    return true;
                }

                var url = $(evt.currentTarget).data("url");
                if ($.trim(url).length > 0) {
                    window.location = url;
                } else {
                    $(this).removeClass("clickable-tile");
                }
                return false;
            });
        };

        $(window).on('init.COL', _init);
    })(),

    CookieBanner: (function () {
        var sel = {
            component: '.cookieBanner',
            close: '.cookieBanner .btn'
        },

            _init = function () {
                $(sel.close).click(function (e) {
                    e.preventDefault();
                    _createCookie();
                    _hideCookie();
                });
                _checkCookie();
            },
            _createCookie = function () {
                var date = new Date(),//Date variable
                    expires = null;//Expires variable
                date.setTime(date.getTime() + (1825 * 24 * 60 * 60 * 1000));//Set date to 5 years from now
                expires = "expires=" + date.toUTCString();//Set the expires variable to the UTC time string
                document.cookie = 'accepted-cookies=true; ' + expires + '; path=/';//Create the cookie
            },
            _checkCookie = function () {
                var cookie__valid;
                var ca = document.cookie.split(';');
                for (var i = 0; i < ca.length; i++) {
                    if (ca[i].indexOf('accepted-cookies=true') != -1) {
                        cookie__valid = 1;
                    }
                }
                if (!cookie__valid) {
                    _showCookie();
                } else {
                    return false;
                }
            },
            _hideCookie = function () {
                $(sel.component).hide();
                $(window).trigger('dismiss.cookiebanner.COL');
            },
            _showCookie = function () {
                $(sel.component).show();
            };

        $(window).on('init.COL', _init);

    })(),

    Accordion: (function () {
        var sel = {
            component: ".accordion-item-container"
        },

            _init = function () {
                if ($(sel.component).length <= 0) return false;
                $(".accordion-item", sel.component).on("click", _toggle);
            },

            _toggle = function (evt) {
                var item = $(evt.currentTarget);
                if (item.hasClass("open")) {
                    item.closest(".accordion-item-container").find(".accordion-item").removeClass("open");
                } else {
                    item.closest(".accordion-item-container").find(".accordion-item").removeClass("open");
                    item.addClass("open");
                }
            };

        $(window).on('init.COL', _init);
    })(),

    Forms: (function () {
        var sel = {},
            _init = function () {
                $("input,select,textarea").each(function () {
                    if ($(this).is(":checkbox")) return;
                    if (($(this).val() != null) && ($(this).val().length > 0)) {
                        $(this).addClass("has-val");
                    }
                });

                $("input,select,textarea").blur(function () {
                    if ($(this).is(":checkbox")) return;
                    if ($(this).val().length > 0) {
                        $(this).addClass("has-val");
                    } else {
                        $(this).removeClass("has-val");
                    }
                });

                $("input,select,textarea").keyup(function () {
                    if ($(this).is(":checkbox")) return;
                    if ($(this).val().length > 0) {
                        $(this).addClass("has-val");
                    } else {
                        $(this).removeClass("has-val");
                    }
                });

                $("input,select,textarea").change(function () {
                    if ($(this).is(":checkbox")) return;
                    if ($(this).val().length > 0) {
                        $(this).addClass("has-val");
                    } else {
                        $(this).removeClass("has-val");
                    }
                });

                $("input,select,textarea").on("fieldchange", function () {
                    if ($(this).is(":checkbox")) return;
                    if ($(this).val().length > 0) {
                        $(this).addClass("has-val");
                    } else {
                        $(this).removeClass("has-val");
                    }
                });

                $("span.checkbox, span.radio").closest("tr").find(".FieldLabel").addClass("nobg");

                if ($("div[id*='BizForm'] .ErrorLabel, div[id*='BizForm'] .InfoLabel").length > 0) {
                    var offset = $("div[id*='BizForm'] .ErrorLabel, div[id*='BizForm'] .InfoLabel").first().offset().top;
                    $('html, body').animate({
                        scrollTop: offset - 100
                    }, 1000, "swing");
                }
            };

        $(window).on('init.COL', _init);
    })(),

    Iframes: (function () {
        var sel = {
            component: 'iframe[src*="youtube.com"],iframe[src*="vimeo.com"]'
        },

            _init = function () {
                if ($(sel.component).length <= 0) return false;
                $(sel.component).each(function () {
                    if ($(this).parents('.embed-container').length <= 0) {
                        $(this).wrap('<div class="embed-container"></div>');
                    }
                })
            };

        $(window).on('init.COL', _init);
    })(),

    SearchResults: (function () {
        var sel = {},

            _init = function () {
                $(".search-results").each(function () {
                    $(".results .list .subtitle").append($(".sugg-subtitle").html());
                    $(".results .list .title .count").append($(".paging-container .summary").html());
                });
            };

        $(window).on('init.COL', _init);
    })(),

    MegaNav: (function () {
        var sel = {},

            _init = function () {
                $(document).on("click", "header nav .nav-item.dropdown .nav-link", _menuToggle);
                $(document).on("click", "header .mobile-controls .control", _mobileMenuToggle);
                $(document).on("click", "header nav .nav-item.dropdown .dropdown-menu .link-back a", _mobileMenuBack);
            },

            _menuToggle = function (evt) {
                var target = $(evt.currentTarget);
                var isopen = target.hasClass("open");
                var navbar = target.closest(".navbar-nav");
                navbar.find(".nav-item.dropdown .nav-link").removeClass("open");
                navbar.find(".nav-item.dropdown .dropdown-menu").removeClass("open");

                if (!isopen) {
                    target.closest("li").find(".dropdown-menu").each(function () {
                        $(this).closest(".tabContent").addClass("slide");
                        $(this).addClass("open");
                    });
                    target.addClass("open");
                }

                if (navbar.find(".nav-item.search .nav-link").hasClass("open")) {
                    navbar.closest("header").find(".search-dropdown").show();
                } else {
                    navbar.closest("header").find(".search-dropdown").hide();
                }

                return false;
            },

            _mobileMenuToggle = function (evt) {
                var target = $(evt.currentTarget);
                var navbar = $("header .navbar");
                var body = $('body');

                var isopen = false;
                if (target.hasClass("control--menu")) {
                    navbar.toggleClass("open");
                    body.toggleClass('no-scroll');
                    navbar.closest("header").find(".search-dropdown").hide();
                    navbar.closest("header").find(".tabContent").removeClass("slide");
                    navbar.closest("header").find(".tabContent .dropdown-menu, .tabContent .nav-link").removeClass("open");

                    if (navbar.hasClass("open")) {
                        isopen = true;
                    }

                } else if (target.hasClass("control--search")) {
                    navbar.closest("header").find(".search-dropdown").toggle();
                    navbar.removeClass("open");

                    if (navbar.closest("header").find(".search-dropdown").is(":visible")) {
                        isopen = true;
                    }
                }

                target.closest(".mobile-controls").find(".control").removeClass("open");
                if (isopen) {
                    target.addClass("open");
                }

                return false;
            },

            _mobileMenuBack = function (evt) {
                var target = $(evt.currentTarget);
                target.closest("header").find(".tabContent").removeClass("slide");

                setTimeout(function () {
                    $("header .nav-item.dropdown .nav-link").removeClass("open");
                    $("header .navbar .dropdown-menu").removeClass("open");
                }, 400);

                return false;
            };

        $(window).on('init.COL', _init);
    })(),

    Page404: (function () {
        var sel = {},

            _init = function () {
                if ($(".page-404, header .search-dropdown").length > 0) {
                    $(document).on("click", ".page-404 .search-form .submit button, header .search-dropdown .search-form .submit button", _searchSubmit);
                    $(document).on("keypress", ".page-404 .search-form .field input, header .search-dropdown .search-form .field input", _checkFormInput);
                }
            },

            _searchSubmit = function (evt) {
                var container = $(evt.currentTarget).closest(".page-404, header .search-dropdown");
                var input = container.find(".field input").val();

                if ($.trim(input).length > 0) {
                    window.location = container.data("submiturl") + "?searchtext=" + encodeURIComponent(input);
                }

                return false;
            },

            _checkFormInput = function (evt) {
                if (evt.keyCode == 13) {
                    _searchSubmit(evt);
                    return false;
                }
            };

        $(window).on("init.COL", _init);
    })(),

    MailchimpSignup: (function () {
        var sel = {},

            _init = function () {
                if ($("#mc-form").length > 0) {
                    $(document).on("click", "#mc-form #mc-submit", _newsletterSubmit);
                    $(document).on("keypress", "#mc-form .EditingFormValueCell input", _checkNewsletterFormInput);
                }
            },

            _checkNewsletterFormInput = function (evt) {
                if (evt.keyCode == 13) {
                    _newsletterSubmit(evt);
                    return false;
                }
            },

            _newsletterSubmit = function (evt) {
                var container = $(evt.currentTarget).closest("#mc-form");
                var firstname = container.find("#mc-firstname").val();
                var lastname = container.find("#mc-lastname").val();
                var email = container.find("#mc-email").val();
                var tc = container.find("#mc-tc").is(":checked");

                var valid = true;
                if ($.trim(firstname).length == 0) {
                    valid = false;

                    var field = container.find("#mc-firstname");
                    var td = field.closest("div");
                    td.find("label").remove();
                    td.append("<label class='error'>" + field.data("message") + "</label>");
                } else {
                    var td = container.find("#mc-firstname").closest("div");
                    td.find("label").remove();
                }

                if ($.trim(lastname).length == 0) {
                    valid = false;

                    var field = container.find("#mc-lastname");
                    var td = field.closest("div");
                    td.find("label").remove();
                    td.append("<label class='error'>" + field.data("message") + "</label>");
                } else {
                    var td = container.find("#mc-lastname").closest("div");
                    td.find("label").remove();
                }

                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (($.trim(email).length == 0) || (!re.test(String(email).toLowerCase()))) {
                    valid = false;

                    var field = container.find("#mc-email");
                    var td = field.closest("div");
                    td.find("label").remove();
                    td.append("<label class='error'>" + field.data("message") + "</label>");
                } else {
                    var td = container.find("#mc-email").closest("div");
                    td.find("label").remove();
                }

                if (valid) {
                    var data = {
                        firstname: firstname,
                        lastname: lastname,
                        email: email,
                        tc: tc
                    };
                    $.get("/positiveapi/newsletter", data, function (response) {
                        if (response == "ok") {
                            container.find(".EditingFormValueCell input").val("").trigger("change");
                            alert("Thank you for your submission");

                        } else {
                            alert(response);
                        }
                    });
                }

                return false;
            };

        $(window).on('init.COL', _init);
    })(),

    SecondaryNav: (function () {
        var sel = {},

            _init = function () {
                if ($(".secondary-nav").length > 0) {
                    $(document).on("click", ".secondary-nav .menu a", _menuToggleEvt);
                    $(document).on("click", ".secondary-nav-mask", _menuClose);

                    $(document).on("click", ".secondary-nav .search-form .submit button", _searchSubmit);
                    $(document).on("keypress", ".secondary-nav .search-form .form-field input", _checkFormInput);

                    $(document).on("click", ".secondary-nav .newsletter-form .submit button", _newsletterSubmit);
                    $(document).on("keypress", ".secondary-nav .newsletter-form .form-field input", _checkNewsletterFormInput);

                    $(window).on("resize", _resize);
                    _resize();
                }
            },

            _resize = function () {
                var ot = $(".secondary-nav .menu-groups").offset().top;
                var h = $(".secondary-nav .menu-group:visible").outerHeight();
                var wh = $(window).height();

                if ((wh - ot) < 800) {
                    $(".secondary-nav .menu-groups").css({ "max-height": (wh - ot) });
                } else {
                    $(".secondary-nav .menu-groups").removeAttr("style");
                }
            },

            _searchSubmit = function (evt) {
                var container = $(evt.currentTarget).closest(".search-form");
                var input = container.find(".form-field input").val();

                if ($.trim(input).length > 0) {
                    window.location = container.data("submiturl") + "?searchtext=" + encodeURIComponent(input);
                }

                return false;
            },

            _checkFormInput = function (evt) {
                if (evt.keyCode == 13) {
                    _searchSubmit(evt);
                    return false;
                }
            },

            _newsletterSubmit = function (evt) {
                var container = $(evt.currentTarget).closest(".newsletter-form");
                var input = container.find(".form-field input").val();

                if ($.trim(input).length > 0) {
                    var data = {
                        email: input
                    };
                    $.get("/positiveapi/newsletter", data, function (response) {
                        if (response == "ok") {
                            container.find(".form-field input").val("");
                            alert("Thank you for your submission");

                        } else {
                            alert(response);
                        }
                    });
                }

                return false;
            },

            _checkNewsletterFormInput = function (evt) {
                if (evt.keyCode == 13) {
                    _newsletterSubmit(evt);
                    return false;
                }
            },

            _menuClose = function (evt) {
                var target = $(evt.currentTarget);
                target.closest(".secondary-nav").removeClass("open");
                $("header .hamburger a").removeClass("open");
                setTimeout(function () {
                    target.closest(".secondary-nav").find(".menu-group.open").hide();
                    target.closest(".secondary-nav").find(".menu a").removeClass("active");
                }, 300);
            },

            _menuToggleEvt = function (evt) {
                var target = $(evt.currentTarget);
                _menuToggle(target);

                return false;
            },

            _menuToggleMain = function (evt) {
                var target = $(".secondary-nav .menu a[href='#menu-nav']");
                _menuToggle(target);

                return false;
            },

            _menuToggle = function (target) {
                var href = target.attr("href");

                if (target.hasClass("active")) {
                    $("header .hamburger a").removeClass("open");
                    target.closest(".secondary-nav").removeClass("open");
                    setTimeout(function () {
                        target.closest(".secondary-nav").find(".menu-group").hide();
                        target.removeClass("active");
                    }, 300);

                } else {
                    target.closest(".secondary-nav").find(".menu-group").hide();
                    target.closest(".secondary-nav").find(".menu a").removeClass("active");
                    target.addClass("active");
                    target.closest(".secondary-nav").find(".menu-group[data-id='" + href.substring(1) + "']").show();

                    $("header .hamburger a").addClass("open");
                    target.closest(".secondary-nav").addClass("open");
                }
                _resize();
            };

        $(window).on('init.COL', _init);
    })(),

    Hero: (function () {
        var sel = {
            component: '.hero',
            videoCTA: '.play-video a',
            scrollIcon: '.hero__scroll',

            modal: '#homepageHeroVideo',
            modalTemplate: '#heroModalTemplate'
        },

            _init = function () {
                if ($(sel.component).length <= 0) return false;
                $(document).on('click', sel.videoCTA, _handleVideoClick);
                $(document).on('click', sel.scrollIcon, _handleScrollClick);
            },
            _handleVideoClick = function (e) {
                e.preventDefault();

                var $modal = _getModalWindow(),
                    url = $(this).attr('data-video');

                $modal.find('iframe').attr('src', url);
                $modal.modal('show');

                //Stops video playing in background
                $modal.one('hidden.bs.modal', function () {
                    $modal.find('iframe').attr('src', '');
                });

                return false;
            },
            _handleScrollClick = function (e) {
                var component = $(sel.component).first();
                var offset = component.offset().top + component.outerHeight();
                $('html, body').animate({
                    scrollTop: offset
                }, 500, "swing");

                return false;
            },
            _getModalWindow = function () {
                if ($(sel.modal).length) {
                    return $(sel.modal);
                }
                var $template = $(sel.modalTemplate).html();
                $('body').append($template);
                return $(sel.modal);
            };

        $(window).on('init.COL', _init);

    })(),

    VideoPlayer: (function () {
        var sel = {
            component: '.video-player',
            videoCTA: '.play-video a',

            modal: '#videoPlayerVideo',
            modalTemplate: '#videoPlayerModalTemplate'
        },

            _init = function () {
                if ($(sel.component).length <= 0) return false;
                $(document).on('click', sel.videoCTA, _handleVideoClick);
            },
            _handleVideoClick = function (e) {
                e.preventDefault();

                var $modal = _getModalWindow(),
                    url = $(this).attr('data-video');

                $modal.find('iframe').attr('src', url);
                $modal.modal('show');

                //Stops video playing in background
                $modal.one('hidden.bs.modal', function () {
                    $modal.find('iframe').attr('src', '');
                });

                return false;
            },
            _getModalWindow = function () {
                if ($(sel.modal).length) {
                    return $(sel.modal);
                }
                var $template = $(sel.modalTemplate).html();
                $('body').append($template);
                return $(sel.modal);
            };

        $(window).on('init.COL', _init);

    })(),

    HomepageCarousel: (function () {
        var sel = {
            carousel: null,
            interval: null,
            index: 0,
            max: 0
        },

            _init = function () {
                sel.carousel = $(".homepage-carousel");
                if (sel.carousel.length != 0) {
                    var blocks = $(".carousel__item", sel.carousel);
                    sel.max = blocks.length;

                    $(".carousel__items", sel.carousel).css("width", "" + (sel.max * 100) + "%");
                    blocks.each(function (index) {
                        $(this).css("width", "" + (100 / sel.max) + "%").show();
                    });

                    var links = $(".carousel__nav li", sel.carousel);
                    links.each(function (index) {
                        $(this).data("index", index);
                    });

                    $(".next-prev", sel.carousel).show();
                    $(document).on("click", ".next-prev .prev", _prev);
                    $(document).on("click", ".next-prev .next", _next);

                    sel.index = 0;
                    var cookieVal = _readCookie("GC.carousel.index");
                    if (cookieVal != null && !isNaN(parseInt(cookieVal))) {
                        sel.index = parseInt(cookieVal);
                    }

                    _slide();
                    sel.interval = setTimeout(function () {
                        _slide();
                        $(".carousel__items", sel.carousel).addClass("transition");
                    }, 100);
                }
            },

            _createCookie = function (name, value, expirySeconds) {
                var expires = "";
                if (expirySeconds) {
                    var date = new Date();
                    date.setTime(date.getTime() + (expirySeconds * 1000));
                    expires = "; expires=" + date.toUTCString();
                }
                document.cookie = name + "=" + value + expires + "; path=/";
            },
            _readCookie = function (name) {
                var nameEQ = name + "=";
                var ca = document.cookie.split(';');
                for (var i = 0; i < ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
                }
                return null;
            },
            _clearCookie = function (name) {
                _createCookie(name, "", -1);
            },

            _next = function () {
                sel.index++;
                if (sel.index >= sel.max) {
                    sel.index = 0;
                }
                _slide();

                return false;
            },

            _prev = function () {
                sel.index--;
                if (sel.index < 0) {
                    sel.index = (sel.max - 1);
                }
                _slide();

                return false;
            },

            _slide = function () {
                _createCookie("GC.carousel.index", sel.index);
                $(".carousel__items", sel.carousel).css("left", "" + (-1 * sel.index * 100) + "%");
                $(".carousel__nav li", sel.carousel).removeClass("active").each(function () {
                    if ($(this).data("index") == sel.index) {
                        $(this).addClass("active");
                    }
                });

                clearInterval(sel.interval);
                sel.interval = setTimeout(function () {
                    _next();
                }, 20000);
            };

        $(window).on('init.COL', _init);
    })(),

    TestimonialCarousel: (function () {
        var sel = {
            carousel: null,
            interval: null,
            index: 0,
            max: 0
        },

            _init = function () {
                sel.carousel = $(".inline-quote");
                if (sel.carousel.length != 0) {
                    var blocks = $(".inline-quote-item", sel.carousel);
                    sel.max = blocks.length;

                    $(".inline-quote-inner", sel.carousel).css("width", "" + (sel.max * 100) + "%");
                    blocks.each(function (index) {
                        $(this).css("width", "" + (100 / sel.max) + "%").show();
                    });

                    if (sel.max > 1) {
                        var links = $(".pills li", sel.carousel);
                        links.each(function (index) {
                            $(this).data("index", index);
                        });

                        $(document).on("click", ".pills li a", _to);

                        sel.interval = setTimeout(function () {
                            _slide();
                            $(".inline-quote-inner", sel.carousel).addClass("transition");
                        }, 100);

                    } else {
                        $(".pills", sel.carousel).hide();
                    }
                }
            },

            _to = function (evt) {
                var target = $(evt.currentTarget).closest("li");
                sel.index = target.data("index");
                _slide();

                return false;
            },

            _next = function () {
                sel.index++;
                if (sel.index >= sel.max) {
                    sel.index = 0;
                }
                _slide();

                return false;
            },

            _prev = function () {
                sel.index--;
                if (sel.index < 0) {
                    sel.index = (sel.max - 1);
                }
                _slide();

                return false;
            },

            _slide = function () {
                $(".inline-quote-inner", sel.carousel).css("left", "" + (-1 * sel.index * 100) + "%");
                $(".inline-quote-inner", sel.carousel).css("height", $(".inline-quote-item[data-index='" + sel.index + "']").outerHeight());

                $(".pills li", sel.carousel).removeClass("on").each(function () {
                    if ($(this).data("index") == sel.index) {
                        $(this).addClass("on");
                    }
                });

                clearInterval(sel.interval);
                sel.interval = setTimeout(function () {
                    _next();
                }, 20000);
            };

        $(window).on('init.COL', _init);
    })(),

    BlogList: (function () {
        var sel = {
            component: '.article-list',
            template: '#blogItemTemplate',
            loading: false
        },

            _init = function () {
                if ($(sel.component).length) {
                    template = $(sel.template).html();
                    _loadMore();
                }
            },

            _loadData = function ($url, $self, $fromStorage) {
                $params = {
                    theme: blogPanelListingConfig.theme,
                    type: blogPanelListingConfig.type
                };
                $.getJSON($url, $params, function (resp) {
                    var data = resp.BlogPanels;
                    var datamore = resp.ShowLoadMoreButton;

                    for (var i = 0; i < data.length; i++) {
                        var $tmpTemplate = $(template).clone();
                        $tmpTemplate.find('.article-list__details').addClass("article-list__details--" + data[i].PageCategoryNameRaw);
                        $tmpTemplate.find('.article-list__details .article-type span').text(data[i].PageCategoryName);

                        var img = $.trim(data[i].Image);
                        if (img.length == 0) {
                            $tmpTemplate.find('.article-list__details .image').hide();
                        } else {
                            $tmpTemplate.find('.article-list__details .image').show();
                            $tmpTemplate.find('.article-list__details .image').attr('style', 'background-image: url(' + data[i].Image + ');');
                        }

                        $tmpTemplate.find('.article-list__details .title').text(data[i].Title);
                        $tmpTemplate.find('.article-list__details .desc span').text(data[i].Summary);

                        if (data[i].PageCategoryName == "News") {
                            $tmpTemplate.find('.article-list__details .desc em').show().text(data[i].Date + " - ");
                        } else {
                            $tmpTemplate.find('.article-list__details .desc em').hide();
                        }

                        $self.find('.article-list__listitems').append($tmpTemplate);

                        var lastElement = $self.find('.article-list__item').last();
                        lastElement.attr("data-url", data[i].Url);
                        lastElement.find(".click-mask").attr("title", data[i].Title);
                        lastElement.find(".click-mask").attr("href", data[i].Url);
                        $(".article-list__listitems").masonry("appended", lastElement);
                    }
                    $self.find(".article-list__listitems .article-list__item.new").each(function () {
                        $(this).removeClass("new");
                        $(this).on("click", function (evt) {
                            var target = $(evt.currentTarget).data("url");
                            window.location = target;
                            return false;
                        });
                    });

                    if (datamore == false) {
                        $self.find(".article-list__status").show();

                    } else {
                        blogPanelListingConfig.skip = parseInt(blogPanelListingConfig.skip) + parseInt(blogPanelListingConfig.take);
                    }

                    sel.loading = false;
                });
            },

            _loadMore = function () {
                $(sel.component).each(function () {
                    $self = $(this);

                    var history = location.hash.slice(1);
                    if (history != "") {
                        if (localStorage.getItem("LoadCount")) {
                            $take = parseInt(localStorage.getItem("LoadTake")) * parseInt(localStorage.getItem("LoadCount"));

                            $url = blogPanelListingConfig.url
                                + "/" + blogPanelListingConfig.currentDocumentNodeGuid
                                + "/" + blogPanelListingConfig.skip
                                + "/" + $take;
                            _loadData($url, $self, false);
                        }
                    } else {
                        //clear the localstorage
                        localStorage.setItem("LoadCount", "");
                        localStorage.setItem("LoadTake", "");
                        localStorage.setItem("lastskip", "");
                    }

                    $(document).on("scroll", _scroll);
                    _scroll();
                });
            },

            _scroll = function () {
                if ($self.find(".article-list__status").is(":visible")) {
                    return;
                }

                var w = $(window);
                var el = $(sel.component);
                var st = w.scrollTop();
                var wh = w.height();
                var ot = el.offset().top;
                var h = el.outerHeight();

                if ((ot + h) < (st + wh)) {
                    if (sel.loading == false) {
                        sel.loading = true;
                        //reset count
                        window.location.hash = "#loadmore";

                        $skip = blogPanelListingConfig.skip;
                        if (localStorage.getItem("lastskip") > 0) {
                            $skip = parseInt(localStorage.getItem("lastskip")) + parseInt(blogPanelListingConfig.take);
                        }
                        localStorage.setItem("lastskip", $skip);

                        $url = blogPanelListingConfig.url
                            + "/" + blogPanelListingConfig.currentDocumentNodeGuid
                            + "/" + $skip
                            + "/" + blogPanelListingConfig.take;
                        _loadData($url, $self, false);

                        var currentLoadcount = localStorage.getItem("LoadCount");
                        localStorage.setItem("LoadTake", blogPanelListingConfig.take);

                        if (currentLoadcount <= 0 || isNaN(currentLoadcount)) {
                            currentLoadcount = 1;
                        } else {
                            currentLoadcount = parseInt(currentLoadcount) + 1;
                        }

                        localStorage.setItem("LoadCount", currentLoadcount);
                    }
                }
            };

        $(window).on('init.COL', _init);
    })(),

    init: function () {
        try {
            document.createEvent("TouchEvent");
            $('body').addClass('touch');
        } catch (e) {
            //nothing
        }

        $(window).trigger('init.COL');
    }
};

$(document).ready(COL.init);